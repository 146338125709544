.providerGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  margin-bottom: 80px;
  justify-items: center;
  align-items: center;
}

.providerButton {
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;

  &:hover {
    opacity: 1;
  }
}

.inactive {
  opacity: 0.4;
}

.dynamicContent {
  min-height: 250px; // Adjust this value as needed
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.contentWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.headline {
  font-size: 2rem; // Adjust as needed
  line-height: 1.2;
  margin-bottom: 1rem;
  // Ensure text doesn't overflow
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.content {
  font-size: 1rem; // Adjust as needed
  line-height: 1.5;
  // Ensure text doesn't overflow
  display: -webkit-box;
  -webkit-line-clamp: 4; // Adjust the number of lines as needed
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.buttonWrapper {
  margin-top: 20px; // Adjust as needed
}
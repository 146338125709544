@keyframes rainbowGlow {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 200% 50%;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rotateIn {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.animatedButton {
  background-color: #247BFE;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  outline: none;
  overflow: visible;
  padding: 34px 38px;
  position: relative;
  z-index: 1;

  &::before {
    background: linear-gradient(45deg, #ff00ff, #00ffff, #FF3874);
    background-size: 200% 100%;
    border-radius: 8px;
    bottom: 0;
    box-shadow: 0 0 8px rgba(0, 255, 255, 0.5);
    content: '';
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: filter 0.4s ease-in-out;
    z-index: -1;
  }

  &::after {
    background-color: #247BFE;
    border-radius: 8px;
    bottom: 2px;
    content: '';
    left: 2px;
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: -1;
  }

  &:hover {
    &::before {
      animation: rainbowGlow 3s linear infinite;
      filter: blur(0);
    }

    &::after {
      filter: blur(2px);
    }

    .checkmarkContainer {
      animation: slideIn 0.3s ease-out forwards;
    }

    .checkmarkWrapper {
      animation: rotateIn 0.3s ease-out forwards;
    }
  }
}

.checkmarkContainer {
  position: absolute;
  right: -24px;
  bottom: -24px;
  pointer-events: none;
}

.checkmarkWrapper {
  transform: rotate(45deg);
}
.carouselContainer {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.carouselTrack {
  display: flex;
  width: fit-content;
  animation: scroll 60s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

.carouselItem {
  flex: 0 0 150px;
  margin-right: 100px;
}

.logoWrapper {
  height: 150px;
  width: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    cursor: pointer;
    filter: grayscale(100%);
    opacity: 0.5;
    border-radius: 50%;
    object-fit: cover;
    transition: filter 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

    &:hover {
      filter: none;
      opacity: 1;
      transform: scale(1.1);
    }
  }

  .icon {
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 30px;
    height: 30px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover .icon {
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% / 2));
  }
}

// Media query for mobile devices
@media (max-width: 768px) {
  .carouselItem {
    flex: 0 0 80px;
    margin-right: 50px;
  }

  .logoWrapper {
    height: 80px;
    width: 80px;

    img {
      height: 60px;
      width: 60px;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
.heroImageContainer {
  position: relative;
  width: 100%;
  height: 600px;
  margin-top: 48px;
  margin-bottom: -1px;
}

.guideImage {
  position: absolute;
  inset: 0;
  background-image: url('/images/brandymail-bimi-vmc.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
}

.heroBrowserContainer,
.heroBoaContainer,
.heroIphoneContainer {
  position: absolute;
  inset: 0;
}

/* Adjust these styles to position each element correctly */
.heroBrowserContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.heroBoaContainer {
  top: 21%;
  left: 1.7%;
  width: 71.5%;
  height: 71.5%;
}

.heroIphoneContainer {
  top: 120px;
  width: 80%;
  height: 80%;
  left: 541px;

  .heroIphone {
    position: absolute;
    height: 81%;
    width: 81%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: contain;
    color: transparent;
  }
}

@media (max-width: 768px) {
  .heroImageContainer {
    height: 211px;
  }

  .heroBoaContainer {
    height: 172px;
    left: 6px;
    top: 36px;
    width: 237px;
  }

  .heroIphoneContainer {
    height: 200px;
    left: 200px;
    top: 26px;
    width: 192px;
  }
}